import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql, Link, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import BaseContainer from "../components/common/BaseContainer"
import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import { Box, Grid } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"

const Heading = styled("div")(({ theme }) => ({
  fontSize: "1.8rem",
  color: theme.palette.primary.main,
  fontWeight: "bold",
}))

const linkBoxSx = { "& a": { color: "primary.main" } }

const Contact = () => {
  const { placeholderImage } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "infografiki_kontakt.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `)
  const image = getImage(placeholderImage)
  const bgImage = convertToBgImage(image)

  const isMedium = useMediaQuery("(max-width:1100px)");
  const isSmall = useMediaQuery("(max-width:800px)");

  let bgStyles = { backgroundSize: "135%", backgroundPosition: "0" };
  if(isMedium) {
    bgStyles = { backgroundSize: "cover", backgroundPosition: "0" };
  }

  if(isSmall) {
    bgStyles = { backgroundSize: "cover", backgroundPosition: "center right" }
  }

  return (
    <Layout>
      <Seo title="Kontakt" />
      <BackgroundImage
        Tag="section"
        {...bgImage}
        style={bgStyles}
        preserveStackingContext
      >
        <BaseContainer
          sx={{
            py: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            "& > *": { textAlign: "right" },
          }}
        >
          <Typography
            variant="h1"
            color="white"
            sx={{
              fontSize: { xs: "96px", lg: "130px" },
              lineHeight: { xs: "90px", lg: "112px" },
              "& span": {
                color: "primary.light",
              },
            }}
          >
            Zapraszamy
            <br />
            do kontaktu
            <br />z firmą
            <br />
            <span>Music Point</span>
          </Typography>
        </BaseContainer>
      </BackgroundImage>
      <BaseContainer sx={{ py: 8, color: "secondary.main" }}>
        <Typography variant="h4" fontSize={"64px"} color={"primary.main"}>
          KONTAKT
        </Typography>
        <Grid container>
          <Grid item xs={12} md={6} mb={4}>
            <Box>
              <Heading>Music Point Sp. z o.o.</Heading>
              <Grid container item xs={6}>
                <Grid item xs={12}>
                  <Typography sx={{ color: "secondary.main" }}>
                    ul. Lipska 5<br />
                    03-904 Warszawa
                  </Typography>
                </Grid>
                <Grid item>
                  <Box
                    display={"grid"}
                    gridTemplateColumns="repeat(2, min-content)"
                  >
                    <Box fontWeight="bold">email: </Box>
                    <Box sx={linkBoxSx}>
                      <a href="mailto:biuro@musicpoint.pl">
                        biuro@musicpoint.pl
                      </a>
                    </Box>
                    <Box gridRow={"span 2"} pr={1} fontWeight="bold">
                      WWW:{" "}
                    </Box>
                    <Box sx={linkBoxSx}>
                      <Link to={"/"}>www.musicpoint.pl</Link>
                    </Box>
                    <Box sx={linkBoxSx}>
                      <a href="https://jaguarec.pl">www.jaguarec.pl</a>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid container item xs={12} md={6}>
            <Grid item sx={{ mb: 4 }}>
              <Box>
                <Heading>Magdalena Sokołowska</Heading>
                <Grid container>
                  <Grid item xs={12} />
                  <Grid item xs={12}>
                    <Typography
                      sx={{ color: "secondary.main", fontWeight: "bold" }}
                    >
                      HEAD OF CLIENT RELATIONS
                      <br />
                      AND MUSIC SUPERVISOR
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      display={"grid"}
                      gridTemplateColumns="repeat(2, min-content)"
                    >
                      <Box pr={1} fontWeight="bold">
                        email:{" "}
                      </Box>
                      <Box sx={linkBoxSx}>
                        <a href={"mailto:magdalena.sokolowska@musicpoint.pl"}>
                          magdalena.sokolowska@musicpoint.pl
                        </a>
                      </Box>
                      <Box pr={1} fontWeight="bold">
                        tel:{" "}
                      </Box>
                      <Box>+48 603 688 722</Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item>
              <Box gridColumn={2}>
                <Heading>Daria Dąbrowska</Heading>
                <Grid container>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{ color: "secondary.main", fontWeight: "bold" }}
                    >
                      CLIENT RELATIONS
                      <br />
                      MUSIC AND PLAYLIST SUPERVISOR
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      display={"grid"}
                      gridTemplateColumns="repeat(2, min-content)"
                    >
                      <Box pr={1} fontWeight="bold">
                        email:{" "}
                      </Box>
                      <Box sx={linkBoxSx}>
                        <a href={"mailto:daria.dabrowska@musicpoint.pl"}>
                          daria.dabrowska@musicpoint.pl
                        </a>
                      </Box>
                      <Box pr={1} fontWeight="bold">
                        tel:{" "}
                      </Box>
                      <Box>+48 504 992 192</Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </BaseContainer>
    </Layout>
  )
}

export default Contact
